import {
  Tabs as TabsUI,
  ITabProps,
} from "@business-finland/wif-ui-lib/dist/Tabs";
import { PromotionBanner as PromotionBannerUI } from "@business-finland/wif-ui-lib/dist/PromotionBanner";

import { Viewport } from "../../../constants/app";
import useImageDataHandler, {
  ImageDataHandlerFn,
} from "../../../hooks/useImageDataHandler";
import {
  ITabBannerProps,
  ITabsProps,
} from "../../../types/wif-promotion-banner";

export function Tabs({
  sectionHeading,
  banners,
}: ITabsProps): JSX.Element | null {
  const imageDataHandler = useImageDataHandler();

  if (!banners || banners.length === 0) return null;

  const tabs = banners.map((banner) =>
    createTabFromBanner(banner, imageDataHandler)
  );

  return <TabsUI sectionHeading={sectionHeading} tabData={tabs} />;
}

function createTabFromBanner(
  banner: ITabBannerProps,
  imageDataHandler: ImageDataHandlerFn
): ITabProps {
  const imageData = imageDataHandler(banner.image, {
    maxViewport: Viewport.sm,
  });

  return {
    tabTitle: banner.tabTitle,
    uid: banner.id,
    content: (
      <PromotionBannerUI
        key={banner.id}
        imageData={{ ...imageData, imageSrc: imageData.src }}
        direction={banner.direction}
        showSaveButton={false}
        body={banner.tabLeadText}
        link={banner.link}
        btnText={banner.btnText}
        imageAlt={banner.imageAlt}
        promotionType="default"
        removeMargin={true}
        isExternalLink={banner.isExternalLink}
        credits={banner.credits}
      />
    ),
  };
}
